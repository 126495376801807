import React from "react";

//Assets
import logo from "../img/logo.png";
import headline from "../img/headline.svg";
import responsiveHeadline from "../img/responsive_headline.svg";

const Header = () => {
  return (
    <div className="text-center p-4">
      <div className="portrait:px-4 lg:px-24 mx-auto lg:mx-0">
        {/* <div className="portrait:mx-auto mx-0 max-w-sm flex flex-row w-64">
          <img src={logo} className="w-16 h-16 object-contain" />
          <img
            src={headline}
            className="ml-2 flex-1 block w-full h-auto min-w-0 object-contain"
          />
        </div> */}
      </div>
    </div>
  );
};
export default Header;
