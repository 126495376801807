import React from "react";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { openModal, closeModal } from "../actions/modal";
import { getModalDetails } from "../selectors/modal";
// import golemaRamka from "../img/slot-machine/golema_ramka.png";
import malaRamka from "../img/slot-machine/ramka_modal.png";


const ErrorModal = ({ modalData, openModal, closeModal, modalId }) => {
  const closingModal = async () => {
    console.log("closing modal");
    closeModal(modalId);
  };
  

  return (
    <>
      {modalData ? (
        <div className="fixed left-0 z-50 top-0 w-full h-full overflow-auto bg-black bg-opacity-50">
          <div className="flex flex-col justify-center h-screen">
            <div
              className="container max-h-200 lg:mb-8 rounded-3xl bg-darkBlue bg-clip-padding max-h-full max-w-5xl mx-auto relative flex justify-center items-center"
              style={{
                border: "30px solid black",
                borderImage: `url(${malaRamka}) 40`,
                borderImageRepeat: "stretch",
              }}
            >
              <div className="flex flex-col h-full w-full justify-between place-content-evenly">
                <div className="min-h-full text-center">
                  <div className="text-yellow text-10p portrait:text-7p lg:text-3xl font-merkurSansBlack leading-none">
                    UUUUPS!
                  </div>
                  {/* <div className="text-white text-16p portrait:text-16p lg:text-8p font-merkurSansBold leading-none">
                    GREŠKA!
                  </div> */}
                  <div className="mt-10">
                    <div className="text-white text-2p portrait:text-4p lg:text-md font-merkurSansLight leading-none">
                        {typeof modalData === 'object' ? (
                          modalData.map((message, idx) => {
                            if (message.url !== '') {
                            return (
                              <>
                              <p>{idx+1}. {message.msg}</p>
                              <p>&nbsp;</p>
                              <a className="text-4p portrait:text-3p bg-yellow text-lightBlue font-merkurSansBlack lg:text-base leading-none rounded-full px-8 py-2 md:px-10" href={message.url} target="_blank" onClick={() => closingModal()}>
                                {message.urlmsg}
                              </a>
                              <p>&nbsp;</p>
                              <p>&nbsp;</p>
                              </>
                            )
                          } else {
                            return (
                              <>
                              <p>{message.msg}</p>
                              <p>&nbsp;</p>
                              </>
                            )
                          }
                          })
                        ) : null }
                        *Bonus mogu ostvariti samo novoregistrovani igrači
                    </div>
                  </div>
                  <div className="mt-6 md:mt-14">
                      <button
                        type="submit"
                        className="text-4p portrait:text-3p bg-yellow text-lightBlue font-merkurSansBlack lg:text-base leading-none rounded-full px-8 py-2 md:px-10"
                        onClick={() => closingModal()}
                      >
                        ZATVORI
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state, { modalId, message }) => ({
  modalData: getModalDetails(state, modalId, message),
});

const mapDispatchToProps = {
  openModal,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
