import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";

const LayoutScreen = () => {
  return (
    <>
      <div className="bg-gradient-to-b from-darkBlue lg:invisible rounded-c5 w-full h-full fixed -z-9">&nbsp;</div>
      <Header></Header>
      <div className="bg-darkBlue fixed top-0 left-0 bottom-0 right-0 bg-bottom bg-no-repeat portrait:bg-contain sm:bg-cover -z-10 overflow-y-hidden" />
      <div className="fixed lg:hidden top-0 left-0 bottom-0 right-0 -z-10" />
      {/* <div className="flex flex-col px-7 portrait:px-7 lg:px-28">
        <div className="mt-10">
          <div className="text-yellow text-10p portrait:text-10p portrait:text-center lg:text-5p font-merkurSansBlack leading-none">
          SUNCE TI,<br />
          KAKAV BONUS!
          </div>
        </div>
      </div> */}
    </>
  );
};

export default LayoutScreen;
