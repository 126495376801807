import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { openModal, closeModal } from "../actions/modal";
import { getModalDetails } from "../selectors/modal";
// import golemaRamka from "../img/slot-machine/golema_ramka.png";
import malaRamka from "../img/slot-machine/ramka_modal.png";

const TermsModal = ({ modalData, openModal, closeModal, modalId }) => {
  const closingModal = async () => {
    console.log("closing modal");
    closeModal(modalId);
  };

  return (
    <>
      {modalData ? (
        <div className="fixed left-0 z-50 top-0 w-full h-full overflow-auto bg-black bg-opacity-50">
          <div className="flex flex-col justify-center lg:h-full">
            <div
              className="container lg:mb-8 rounded-3xl bg-darkBlue bg-clip-padding max-w-6xl mx-auto relative flex justify-center items-center"
              style={{
                border: "30px solid black",
                borderImage: `url(${malaRamka}) 40`,
                borderImageRepeat: "stretch",
              }}
            >
              <div className="flex flex-col h-full w-full justify-between place-content-evenly">
                <div className="min-h-full text-center overflow-y">
                  <div className="text-yellow text-10p portrait:text-6p lg:text-3xl font-merkurSansBlack leading-none">
                    SUNCE TI, KAKAV BONUS!
                  </div>
                  <div className="mt-5">
                    <div className="text-white text-justify text-3p md:text-2p portrait:text-4p lg:text-xs font-merkurSansLight leading-none">
                        <p>
                        Registruj se i naše Sunce će te ogrejati sjajnim bonusima
                        </p><p>&nbsp;</p>
                        <p>
                            Leto nam je donelo toliko željene sunčeve zrake, a naše Sunce vam donosi gomilu sjajnih bonusa. Sve nove članove naše porodice, koji se registruju na www.merkurxtip.rs, od 1. jula do 31. jula, očekuje Bonus dobrodošlice „Sunca“ u iznosu od 100 do 900 odsto uplaćenog depozita, do maksimalnih 10.000 dinara uplate.
                        </p><p>&nbsp;</p>
                        <p>
                        ZVUČI JEDNOSTAVNO? VERUJTE NAM DA JESTE. Registruj se, uplati depozit i Sunce ti donosi bonus do devet puta uplaćenog depozita, a to je potencijalno devet puta veća šansa da se igrate s nama.
                        </p><p>&nbsp;</p>
                        <p>
                        Po završetku registracije, bonus se ostvaruje putem elektronske igrice (nalik slotu) koja izbacuje različit broj sunca. Svako sunce ima vrednost 100 odsto bonusa, a što više sunca – veći iznos bonusa. Na primer, ako vam izađu tri sunca to je 300 odsto bonusa, pet sunaca je 500 odsto i tako do maksimalnih devet sunaca ili 900 odsto prvog depozita.
                        </p><p>&nbsp;</p>
                        <p>
                        Vaš MerkurXtip, uvek blizu!
                        </p><p>&nbsp;</p>
                        <p className="font-merkurSansBold">
                        Uslovi:
                        </p>
                        <ul>
                        <li>
                        - Da bi se ostvarilo pravo na Sunce bonus, dodeljeni vaučer/bonus sredstva se ne smeju trošiti na odigravanje pre uplate prvog depozita
                        </li>
                        <li>
                        - Da bi se ostvario Sunce bonus, bonus igru je potrebno pokrenuti nakon uplate prvog depozita a pre odigravanja bilo koje igre
                        </li>
                        <li>
                        - Prvi depozit se mora uplatiti pre pokretanja igrice, odnosno uplata depozita je korak pre informacije o visini bonusa
                        </li>
                        <li>
                        - Da bi se otključao Bonus dobrodošlice „Sunca“ , do maksimalnih 10.000 dinara depozita, neophodno je da se odigraju tiketi u vrednosti dodeljenog bonusa puta šest, sa minimalnim brojem od četiri događaja na tiketu i pojedinačnom kvotom od 1.35
                        </li>
                        <li>
                        - Da bi se otključao Bonus dobrodošlice „Sunca“, do maksimalnih 10.000 dinara depozita na slot igrama, neophodno je izvrteti uloge u visini od 20x iznosa dodeljenog bonusa
                        </li>
                        <li>
                        - Igre koje učestvuju u otključavanju: sportsko klađenje, klađenje uživo, kazino igrice sa oznakom Jackpota (znak dolara)
                        </li>
                        <li>
                        - Igre koje se mogu igrati sa otključanim bonusom su: sportsko klađenje, klađenje uživo, kazino i kazino uživo.  Virtuelne igrice se ne mogu igrati sa otključanim bonusom (na primer Lucky Six, Trke pasa)
                        </li>
                        <li>
                        - U slučaju dobitka na klađenju, vrednost dobitka, umanjena za vrednost uplate, se prebacuje na korisnički račun i postaje „pravi novac“. U slučaju kazina, vrednost dobitka u celosti se prebacuje na korisnički račun i postaje „pravi novac“
                        </li>
                        <li>
                        - Period otključavanja bonusa od momenta uplate je 45 dana
                        </li>
                        <li>
                        - Vreme odigravanja otključanog bonusa je 30 dana
                        </li>
                        </ul>
                    </div>
                  </div>
                  <div className="mt-6">
                      <button
                        type="submit"
                        className="text-sm bg-yellow text-lightBlue font-merkurSansBlack lg:text-base leading-none rounded-full px-8 py-2 md:px-10"
                        onClick={() => closingModal()}
                      >
                        ZATVORI
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state, { modalId }) => ({
  modalData: getModalDetails(state, modalId),
});

const mapDispatchToProps = {
  openModal,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsModal);
