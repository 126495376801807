import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";

//Components
import LayoutScreen from "../components/LayoutScreen";
import { login, register } from "../actions/user";
import { createSession } from "../actions/gameSession";
import { getUserDetails } from "../selectors/user";
import ErrorModal from "../components/ErrorModal";
import TermsModal from "../components/TermsModal";
import { openModal, closeModal } from "../actions/modal";
import { getModalDetails } from "../selectors/modal";
import { TERMS_MODAL_ID, ERROR_MODAL_ID } from "../consts/modalIds";
// import Loader from "../components/Loader";
import CircularProgress from '@mui/material/CircularProgress';

const UserSchema = yup.object().shape({
  identifier: yup
    .string()
    .required("Ovo polje je obavezno")
    .email("Nevalidni e-mail"),
  terms: yup
    .boolean()
    .required("Ovo polje je obavezno")
    .oneOf([true], "Ovo polje je obavezno"),
});

const startValues = {
  identifier: "",
  terms: false,
  gamegiftcode: ""
};


const Homepage = ({ openModal, closeModal, register, login, createSession }) => {
  // const {
  //   userData,
  //   isFetching: userDataIsFetching,
  //   isFetched: userDataIsFetched,
  // } = userDetails;
  const [initialValues, setInitialValues] = useState(startValues);

  const history = useHistory();

  let errorMessage = "";

  const [loading, setLoading] = useState(false);

  const {search} = useLocation();
  const params = new URLSearchParams(search);
  const username = params.get('username');
  const game_gift_code = params.get('game_gift_code');
  console.log(username, game_gift_code);

  const openErrorModal = async (data) => {
    // errorMessage = "<ol>";
    // if (typeof data === 'object') {
    //   data.map((message, idx) => {
    //     errorMessage += `<li>` + (idx+1) + `. ` + message + `\
    //     </li>`;
    //   });
    //   errorMessage += "</ol>"
    //   openModal(ERROR_MODAL_ID, errorMessage);
    // } else {
    //   openModal(ERROR_MODAL_ID, data);
    // }
    openModal(ERROR_MODAL_ID, data);
  };

  const openTermsModal = async () => {
    openModal(TERMS_MODAL_ID, true);
  };
  
  const formSubmit = async (values) => {
    let user = null;
    setLoading(true);
    try {
      user = await login({ identifier: values.identifier, game_gift_code: values.gamegiftcode });
    } catch (e) {
      // if (e.response.data.message)
      //   console.log(e.response.data.message[0].messages[0].message);
      // else 
      //   console.log(e);
      await register({ email: values.identifier, game_gift_code: values.gamegiftcode });
      try {
        user = await login({ identifier: values.identifier, game_gift_code: values.gamegiftcode });
      } catch (e) {
        // console.log(e.response.data.statusCode);
        if (e.length > 0 && e.response.data.statusCode === 403) {
          // location.reload();
          const errorResponse403 = [{msg: e.response.data.message || '', url: '', urlmsg: ''}];
          // errorMessage = e.response.data.message;
          openErrorModal(errorResponse403);
        } else if (e.length > 0 && e.response.data.statusCode == 400) {
          const errorResponse400 = [{msg: e.response.data.message[0].messages[0].message, url: '', urlmsg: ''}];
          // errorMessage = e.response.data.message[0].messages[0].message;
          openErrorModal(errorResponse400);
        } else {
          console.log(e);
        }
        // else 
        //   console.log(e);
        // if (e.statusCode === 403)
        // location.reload();
      }
    }
    if (user) {
      // Attempt to create slot machine session
      try {
        await createSession({
          game: "slot-machine",
        });
        history.push("/slotMachine");
        setLoading(false);
      } catch (e) {
        let validationErrors = [];
        if (e.response.data && Object.keys(e.response.data.data).length > 0) {
          e.response.data.data.map((errorCode, idx) => {
            switch (errorCode) {
              case 20020:
                validationErrors.push({msg: 'Da biste učestvovali, potrebno je da se registrujete i kompletno zavrsite registraciju na MerkurXtip sajtu', url: 'https://merkurxtip.rs/desk/sr/registracija', urlmsg: 'ZAVRŠI REGISTRACIJU'});
                break;
              case 20021:
                validationErrors.push({msg: 'Potrebno je da uplatite depozit i odmah nakon toga pokrenete igricu', url: 'https://merkurxtip.rs/desk/sr/banka-uplata', urlmsg: 'UPLATI DEPOZIT'});
                break;
              case 20022:
                validationErrors.push({msg: 'Igrači koji su prethodno koristili bonus dobrodošlice, nemaju pravo učešća', url: '', urlmsg: ''});
                break;
              case 20023:
                validationErrors.push({msg: 'Nažalost, ne možete učestvovati ukoliko ste prethodno igrali neke od igara na MerkurXtip sajtu', url: '', urlmsg: ''});
                break;
              case 20024:
                validationErrors.push({msg: 'Da biste učestvovali, potrebno je da se registrujete i kompletno zavrsite registraciju na MerkurXtip sajtu', url: 'https://merkurxtip.rs/desk/sr/registracija', urlmsg: 'REGISTRUJ SE'});
                break;
              default:
                break;
            }
          });
          setLoading(false);
          openErrorModal(validationErrors);
        } else {
          switch (e.response.data.data) {
            case 20010:
              history.push("/playedAlready");
              break;
            case 20000:
              history.push("/noBonus");
              break;
            // case 20001:
            //   history.push("/alreadyHaveAccount");
            //   break;
            default:
              break;
          }

          setLoading(false)
        }
      }
    }
  };


  useEffect(() => {
    if (username && game_gift_code) {
      formSubmit({identifier: username, terms: true, gamegiftcode: game_gift_code});
    }
  }, []); // Make sure the effect runs only once


  return (
    <>
      <TermsModal modalId={TERMS_MODAL_ID} />
      <ErrorModal modalId={ERROR_MODAL_ID} />      
      {/* {loading && <Loader />} */}
      {loading && 
      <div
        style={{ 
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000e20',
          zIndex: 1000
        }}
      >
        <CircularProgress />
      </div>}
      <LayoutScreen></LayoutScreen>
      {/* <div className="px-7 portrait:px-7 lg:px-28 flex flex-col items-stretch">
        <div className="mt-5 md:mt-10">
          <div className="text-white portrait:text-center text-4_5p portrait:text-4_5p lg:text-2p font-merkurSansBold leading-none">
          Registruj se,<br />
          uplati depozit i uvećaj ga
          </div>
          <div className="text-yellow portrait:text-center text-4_5p portrait:text-4_5p lg:text-2p font-merkurSansBold leading-none">
            od 100% do čak 900% 
          </div>
        </div>
        <div className="mt-5 md:mt-10 md:w-128 portrait:text-center portrait:w-full">
          <div className="text-yellow text-center text-3p portrait:text-3p lg:text-md font-merkurSansBold leading-none pb-2">
            Zavrti sunce
          </div>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validationSchema={UserSchema}
            enableReinitialize={true}
            onSubmit={formSubmit}
          >
            <Form className="text-center">
              <Field
                name="identifier"
                type="email"
                placeholder="Tvoja email adresa"
                className="merkurSansLight rounded-full max-w-full w-96 py-2 text-center border-white flex-1 appearance-none border border-white bg-white focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
              ></Field>
              <div className="text-white text-center">
                <ErrorMessage name="identifier" />
              </div>
              <div className="text-center mt-5 text-white">
                <label className="inline-flex items-center text-xxs text-white font-extrabold font-merkurSansLight ">
                  <Field
                    type="checkbox"
                    name="terms"
                    className="h-5 w-5 text-red-600"
                    // value={true}
                  ></Field>
                    <span className="text-xxs text-white text-center font-extrabold font-merkurSansLight">
                      &nbsp;&nbsp; Prihvatam
                    </span> 
                  <Link to="#" onClick={() => openTermsModal()}>

                    <span className="mt-5 ml-2 text-xxs text-white text-center font-extrabold font-merkurSansLight underline">
                      pravila igre
                    </span>
                  </Link>
                </label>
                <div className="text-white text-center">
                  <ErrorMessage name="terms" />
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="text-header bg-yellow font-merkurSansBlack text-md md:text-base rounded-full px-10 md:px-16 mt-5 focus:outline-none"
                >
                  START
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div> */}
    </>
  );
};

const mapStateToProps = (state, { modalId }) => ({
  modalData: getModalDetails(state, modalId),
  // userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  register,
  login,
  createSession,
  openModal,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
